import React from 'react';

import Layout from '../components/Layout';

import SectionLinks from '../components/SectionLinks'
import Section from '../components/Section';
import Project from "../components/Project"
import Specialization from "../components/Specialization"
import ConnectFormik from "../components/connectFormik"

import projects from "../../projects"
// "courses" is more apt for the filename as not all courses are part of specializations
import { specializations } from "../../courses"

import config from '../../config';


const SECTIONS = [
  { id: "projects", className: "wrapper special style2", data: projects, Component: Project },
  { id: "courses", className: "wrapper style5 special", data: specializations, Component: Specialization },
  { id: "contact", className: "wrapper style4 special", Component: ConnectFormik }
];

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        {/* kinda lame, but using this so that I can pass <br/> thru */}
        <p dangerouslySetInnerHTML={{__html: config.subHeading}}></p>
        <SectionLinks sections={SECTIONS}></SectionLinks>
      </div>
    </section>
    {
      SECTIONS.map((section, i) => <Section {...section} key={i}/>)
    }
  </Layout>
);

export default IndexPage;
