const INVALID_EMAIL = `You must provide an email where you can be reached.`
const SENDING_CONTACT_REQUEST = `Sending contact request...`
const CORRECT_ERRORS_PROMPT = `Please correct the errors below`
const TRY_AGAIN_LATER = `Something went wrong. Please try again later.`
const OK_RESPONSE = `Success! Your message has been sent. Thank you. Have a great day.`
const INTRO_PLACEHOLDER_TEXT = `Hi! Tell me about yourself. What do you like about yourself? What great things can we do together?`

module.exports = {
	INVALID_EMAIL,
	SENDING_CONTACT_REQUEST,
	CORRECT_ERRORS_PROMPT,
	TRY_AGAIN_LATER,
	OK_RESPONSE,
	INTRO_PLACEHOLDER_TEXT
};