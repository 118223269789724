import smoothscroll from 'smoothscroll-polyfill';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Scroll = props => {
  Scroll.propTypes = {
    type: PropTypes.string,
    element: PropTypes.string,
    offset: PropTypes.number,
    timeout: PropTypes.number,
    children: PropTypes.node.isRequired,
  };
  
  useEffect(() => smoothscroll.polyfill(), [])
  const clickHandler = getClickHandler(props) 

  return (
    typeof props.children === 'object'
      ? React.cloneElement(props.children, { onClick: clickHandler })
      : <span onClick={clickHandler}>{props.children}</span>
  );
}


function getScrollTarget(element, type) {
  switch (type) {
    case 'class':
      return document.getElementsByClassName(element)[0];
    case 'id':
      return document.getElementById(element);
    default:
  }
}

function scrollTo(element, offSet = 0, timeout = null) {
  const elemPos = element
    ? element.getBoundingClientRect().top + window.pageYOffset
    : 0;
  if (timeout) {
    setTimeout(() => {
      window.scroll({ top: elemPos + offSet, left: 0, behavior: 'smooth' });
    }, timeout);
  } else {
    window.scroll({ top: elemPos + offSet, left: 0, behavior: 'smooth' });
  }
}

function getClickHandler({ type, element, offset, timeout }) {
  return (e) => {
    e.preventDefault();    
    const {hash} = e.target
    window.history.replaceState({}, '', hash)
    const y = (type && element)
      ? getScrollTarget(element, type)
      : 0;
    y
      ? scrollTo(y, offset, timeout)
      : console.log(`Element not found: ${element}`); // eslint-disable-line
  }
}

export default Scroll;