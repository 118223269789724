import React from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"

import { postData } from "./browser"
import { validateContacterParams } from "../../services/validation"

import * as MSGS from "../../msgs"

const ERROR_CLASS_NAME = "error"
const CONNECT_URL = '/connect'
const DO_CLIENT_SIDE_VALIDATION = true

export default () => {
  let isRequestSent = false;

  return <Formik
    initialValues={{ EMAIL: '', FULLNAME: '', INTRO: '' }}
    validate={(params) => {
      // console.log("params: ", params)
      const result = DO_CLIENT_SIDE_VALIDATION
        ? validateContacterParams(params)
        : null
      // console.log("result: ", result)
      return result;
    }}
    onSubmit={(values, actions) => {
      // console.log("onSubmit - values: ", values)

      actions.setStatus({ msg: MSGS.SENDING_CONTACT_REQUEST });
      postData(CONNECT_URL, values)
        .then(
          handleResponse(actions),
          handleNetworkError(actions)
        ).then(() => actions.setSubmitting(false));
    }}
    render={({ status = {}, isSubmitting }) => {
      return <div className="inner">
        { 
            (!isRequestSent || status.className === ERROR_CLASS_NAME)
            ? <> 
                {
                  status.msg &&
                  <div className={`status ${status.className || ""}`}>
                    {status.msg}
                  </div>
                }
                <Form action={CONNECT_URL} method="POST">
                  <legend>
                      <div className="row gtr-uniform">
                          <div className="col-6 col-12-xsmall">
                              <ErrorMessage name="FULLNAME" component="div" className={ERROR_CLASS_NAME} />
                          </div>
                          <div className="col-6 col-12-xsmall">
                              <ErrorMessage name="EMAIL" component="div" className={ERROR_CLASS_NAME} />
                          </div>                        
                          <div className="col-6 col-12-xsmall">
                              <Field type="text" name="FULLNAME" placeholder="Full Name" />
                          </div>
                          <div className="col-6 col-12-xsmall">
                              <Field type="email" name="EMAIL" placeholder="Email" />
                          </div>
                          <div className="col-12">
                              <ErrorMessage name="INTRO" component="div" className={ERROR_CLASS_NAME} />
                              <Field component="textarea" name="INTRO" rows="5" placeholder={MSGS.INTRO_PLACEHOLDER_TEXT} />
                          </div>
                          <div className="col-2 col-6-xsmall">
                              <button className="primary" type="submit" disabled={isSubmitting}>Submit</button>
                          </div>
                      </div>
                  </legend>
                </Form>
              </>
            : null
        }
        {isRequestSent &&
          <div className="requestMade">{MSGS.OK_RESPONSE}</div>
        }
      </div>
    }}
  />

  function handleResponse(actions) {
    return function(response) {
      if (response.ok) {
        isRequestSent = true
        actions.setStatus({ msg: '' });
      } else {
        if (response.status >= 500) {
          actions.setStatus({ msg: MSGS.TRY_AGAIN_LATER, className: ERROR_CLASS_NAME })
        }
        else {
          response.json().then(responseJSON => actions.setErrors(responseJSON))
          actions.setStatus({ msg: '' })
        }
      }
    }
  }
}

function handleNetworkError(actions) {
  return function(err) {
    console.log(`error: `, err)
    actions.setStatus({
      msg: MSGS.TRY_AGAIN_LATER,
      className: ERROR_CLASS_NAME
    })
  }
}