import React from "react"

import ScrollButton from './ScrollButton'
import { capitalizeFirst } from '../utils'


const BackToTopButton = () =>
    <ScrollButton target="banner" text="Back to Top" buttonClassName="backToTop" />

export default ({id, text, className, children, Component, data}) =>
    <section id={id} className={className}>
        <BackToTopButton />  
        <div className="inner">
            <header className="major">
                <h2>{text || capitalizeFirst(id) }</h2>
            </header>
            {
                children
                    ? children
                    : data
                        ? data.map((datum, i) => <Component {...datum} key={i}/>)
                        : <Component />
            }
        </div>
    </section>