import React from "react"

const paths = {
    'course': 'records',
    'specialization': 'specialization'
}

const getVerifyUrl = (verifyID, type = 'course') =>
    `//www.coursera.org/account/accomplishments/${paths[type]}/${verifyID}`

export default ({title, verifyID, courses}) =>
    <article>
        <h3>
            {(
                verifyID
                    ?
                        <a href={getVerifyUrl(verifyID, 'specialization')}>
                            {title}
                        </a>
                    : title
            )}
        </h3>
        <table className="alt">
            <thead>
                <tr>
                    <th>Course</th>
                    <th>Score</th>
                </tr>
            </thead>
            <tbody>
                {
                    courses.map(({title, grade, verifyID}) => {
                        const verifyLink = getVerifyUrl(verifyID)
                        return <tr key={verifyID}>
                            <td><a href={verifyLink}>{title}</a></td>
                            <td><a href={verifyLink}>{grade}</a></td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    </article>