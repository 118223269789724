import React from "react"

import ScrollButton from './ScrollButton';

export default ({sections}) =>
  <ul className="actions special">
      {sections.map(
          section =>
              <li key={section.id}>
                  <ScrollButton target={section.id} {...section} />
              </li>
      )} 
  </ul>