// Weird! Need to use 'validate.js' (not 'validate') bc that's what author called the module
const validate = require('validate.js');

const REQUIRED = { allowEmpty: false };

validate.validators.optional = function(value, options) {
  if (!value || value.trim() === '') {
    return null;
  }

  return validate.single(value, options);
};

const onlyReturnOneErrorPerParam = (validationResult = {}) => {
	Object.keys(validationResult).forEach(paramName => {
		const errors = validationResult[paramName]
		if (errors.length > 1) {
			validationResult[paramName] = errors.slice(0, 1)
		}
	})
}
const validateContacterParams = (function() {
	const CONSTRAINTS = {
		FULLNAME: {
			presence: REQUIRED,
			// ex: "John Smith"
			format: { pattern: /^[^<>&;]+(\s+[^<>&;]+)*$/ }
		},	
		EMAIL: {
			presence: REQUIRED,
			email: {
				message: "is invalid"
			}
			//format: { pattern: /^[a-zA-Z0-9\.]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/ } // ex: "johnsmith@gmail.com"
		},
		INTRO: {
			presence: REQUIRED,
			// should prevent HTML/JS; had trouble extracting into a constant
			format: { pattern: /^[a-zA-Z0-9.,?!'"`\-\s]+$/ }
		}
	};

	return (params) => {
		// console.log("validating: \n", params);
		// console.log('against constraints: ', CONSTRAINTS)
		const validationResult = validate(params, CONSTRAINTS);
		// console.log("validationResult: ", validationResult);
		onlyReturnOneErrorPerParam(validationResult)
		// console.log("returning validationResult: ", validationResult);
		//return Object.keys(validationResult).length ? validationResult : null;
		return validationResult;
	};
})();

module.exports = { validateContacterParams };