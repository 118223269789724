
export const IS_BROWSER = typeof window !== 'undefined';

const DEFAULT_OPTIONS = {
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
};

export function postData(url, data, opts) {
  // Default options are marked with *
  const options = Object.assign({}, DEFAULT_OPTIONS)
  if (opts) Object.assign(options, opts)
  return window.fetch(url, {
    ...options,
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
}
