import React from "react"

import Tag from "./Tag"

export default ({url, name, description, screenshot, tags}) => {
    return <section className="spotlight">
        <div className="image">
            <a href={url} className="projectScreenshot">
                <img src={screenshot} alt="Under Construction" />
            </a>
        </div>
        <div className="content">
            <h2>
                <a href={url}>{name}</a>
            </h2>
            <p>{description}</p>
            <h4>Built with: </h4>
            <ul className="icons">
                {tags.map(tag =>
                    <li key={name + '-' + tag}>
                        <Tag label={tag} />
                    </li>
                )}  
            </ul>
        </div> 
    </section>
}