import React from "react"

import Scroll from './Scroll';
import { capitalizeFirst } from '../utils'

export default ({text, target, buttonClassName = 'button primary'}) => 
  <Scroll type="id" element={target}>
    <a href={"/#" + target} className={buttonClassName}>
      {text || capitalizeFirst(target)}
    </a>
  </Scroll>
